<template>
  <Outer>
    <div class="b-auth">
      <h2 class="b-title _center">
        {{ $t('auth---Unfortunately, authorization failed') }}
      </h2>
      <p class="b-text _note _center">
        {{ $t('auth---Missing email in your profile. Contact your curator.') }}
      </p>
      <div class="b-row _space_above _centered">
        <a href="#" @click.prevent="$router.push({ name: 'auth-log-in' })"
           class="b-link">{{ $t('auth---Back to main page') }}</a>
      </div>
    </div>
  </Outer>
</template>

<i18n src="../i18n/translations/codePage.json"></i18n>

<script>
import Outer from '@/components/Outer.vue';

export default {
  components: {Outer},
};
</script>
